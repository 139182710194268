import { useCallback } from 'react';

import { sendEvent } from '../sendEvent';
import {
  AutocompleteEventType,
  DestinationAutocompleteTrackingObject,
  TrackingEvent,
} from '../types';
import { Option } from '@UX/Forms/MultiSelect/MultiSelect';

export interface DestinationAutocompleteTrackingParams {
  searchTerm: string;
  options: Option[];
  selectedOptions: Option[];
  isOptionsLoaded: boolean;
  isListOpened: boolean;
}

export const useDestinationAutocompleteTracking = (type: AutocompleteEventType) =>
  useCallback(
    ({
      searchTerm,
      options,
      selectedOptions,
      isOptionsLoaded,
      isListOpened,
    }: DestinationAutocompleteTrackingParams) => {
      const optionValues: string[] = [];
      const preparedOptions: Pick<Option, 'value' | 'label' | 'available'>[] = [];

      options.forEach(({ value, label, available }) => {
        preparedOptions.push({ value, label, available });
        optionValues.push(value);
      });

      const trackingObject: DestinationAutocompleteTrackingObject = {
        event: TrackingEvent.destinationAutocomplete,
        type,
        searchTerm,
        isOptionsLoaded,
        options: preparedOptions,
        selectedOptions: selectedOptions.map(({ value, label }) => ({
          label,
          value,
          index: optionValues.indexOf(value),
        })),
        isListOpened,
      };

      sendEvent(trackingObject);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
