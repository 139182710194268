import { Label, Skeleton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { SxStyleProp } from 'theme-ui';
import { useQuery } from 'urql';

import { AccomodationsSection } from './AccomodationsSection';
import { DestinationsSection } from './DestinationsSection';
import getDestinations from './getDestinations.gql';
import { SelectedTags } from '../SelectedTags/SelectedTags';
import { TextField } from '../TextField/TextField';
import { Option } from '../types';
import { Query } from '@AuroraTypes';
import { isAnyDestination } from '@Client/utils/isAnyDestination';
import { unique } from '@Core/helpers/array';
import { useUpdateEffect } from '@Core/hooks/useUpdateEffect';
import { isQueryResolved } from '@Core/isQueryResolved';
import { Key } from '@Core/Key';
import { useDestinationAutocompleteTracking } from '@Core/tracking/hooks/useDestinationAutocompleteTracking';
import { AutocompleteEventType } from '@Core/tracking/types';
import { useSearchAvailabilityStore, useSearchSelectionStore } from '@Stores/StoreContext';

const LoadingResults = () => (
  <React.Fragment>
    {Array(5)
      .fill('')
      .map((_, index) => (
        <div
          key={index}
          sx={{
            paddingY: '3xs',
          }}
        >
          <Skeleton sx={{ width: '100%' }} />
        </div>
      ))}
  </React.Fragment>
);

export const tagsAndInputWrapperStyles: SxStyleProp = {
  paddingY: '2xs',
  borderBottomWidth: 'outlinedStrokeWeight',
  borderBottomStyle: 'solid',
  borderBottomColor: 'strokeLightneutral',
  position: 'sticky',
  top: 0,
  zIndex: 'stickyContent',
  backgroundColor: 'backgroundWhite',
  marginX: '-xs',
  paddingX: '2xs',
  borderTopLeftRadius: '12',
  borderTopRightRadius: '12',
};

export const innerInputWrapperStyles: SxStyleProp = {
  minHeight: '48px',
  display: 'flex',
  padding: '3xs',
  paddingY: '4xs',
  border: '2px solid',
  borderColor: 'strokePrimary',
  borderRadius: '8',
  alignItems: 'center',
};

interface DestinationSelectorProps {
  onHotelClick: (hotel: any) => void;
  closeModal: () => void;
  mainContentWrapperStyles?: SxStyleProp;
}

export const DestinationSelector: React.FC<DestinationSelectorProps> = memo(
  ({ onHotelClick, closeModal, mainContentWrapperStyles }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [prevSearchTerm, setPrevSearchTerm] = useState(searchTerm);
    const availableDestinationOptions = useSearchAvailabilityStore(({ destinations }) =>
      destinations.map(
        (d) =>
          ({
            value: d?.id,
            label: d?.name,
          }) as Option,
      ),
    );
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [prevLookupResults, setPrevLookupResults] = useState<Option[]>([]);

    const destinationInputTracking = useDestinationAutocompleteTracking(
      AutocompleteEventType.inputChanged,
    );
    const destinationOptionTracking = useDestinationAutocompleteTracking(
      AutocompleteEventType.optionSelected,
    );
    const destinationLoadedTracking = useDestinationAutocompleteTracking(
      AutocompleteEventType.optionsLoaded,
    );

    const searchSelection = useSearchSelectionStore((state) => ({
      boardBasis: state.boardBasis,
      cancellationPolicy: state.cancellationPolicy,
      date: state.date,
      departureAirports: state.departureAirports,
      flexibility: state.flexibility,
      nights: state.nights,
      rooms: state.rooms,
      filters: state.filters,
      source: state.source,
    }));
    const [destinationIds, setDestinationIds] = useSearchSelectionStore((state) => [
      state.destinationIds,
      state.setDestinationIds,
    ]);

    const [{ data, fetching }] = useQuery<Query>({
      query: getDestinations,
      variables: {
        query: searchTerm,
        searchSelection,
        includeImages: !searchTerm,
      },
    });

    const isLoading = !isQueryResolved(fetching, data);

    const { lookupResults, accomodations, destinations } = useMemo(() => {
      let lookupResults: Option[] = [];
      let accomodations: Option[] = [];
      let destinations: Option[] = [];

      if (!isLoading) {
        lookupResults = (data?.Lookup?.destinations || [])
          .map((item: any) => {
            const isAccomodation = !!item.accommodation;
            const destinationDetail = item.accommodation ? item.accommodation : item.destination;

            if (!destinationDetail) {
              return null;
            }

            const option: Option = {
              value: item.id,
              label: destinationDetail.name,
              secondaryLabel: destinationDetail.locationLabel,
              available: isAccomodation ? true : item.available,
              type: isAccomodation ? 'accomodation' : 'destination',
              selected: destinationIds.includes(item.id),
              icon: item.icon,
              image: item.image,
            };

            return option;
          })
          .filter(Boolean) as Option[];

        accomodations = lookupResults.filter((l) => l?.type === 'accomodation');
        destinations = lookupResults.filter((l) => l?.type !== 'accomodation');
      }

      return {
        lookupResults,
        accomodations,
        destinations,
      };
    }, [data, destinationIds, isLoading]);

    const selectedDestinationOptions = destinationIds
      .map((id) =>
        [...prevLookupResults, ...availableDestinationOptions].find((d) => d.value === id),
      )
      .filter(Boolean) as Option[];

    const addDestinationId = (id: string) =>
      isAnyDestination(id)
        ? setDestinationIds([id])
        : setDestinationIds(unique([...destinationIds.filter((o) => !isAnyDestination(o)), id]));

    const removeDestinationId = (id: string) =>
      !isAnyDestination(id) &&
      setDestinationIds(destinationIds.filter((destinationId) => destinationId !== id));

    const handleHotelClick = (option: Option) => {
      if (option.type !== 'accomodation') {
        return;
      }

      const hotel = data?.Lookup.destinations.find((item) => item.id === option.value);
      destinationOptionTracking({
        searchTerm,
        options: lookupResults || [],
        selectedOptions: [option, ...selectedDestinationOptions],
        isOptionsLoaded: !isLoading,
        isListOpened: true,
      });

      if (hotel) {
        onHotelClick(hotel);
      }
    };

    const onSelect = (id: string, isSelected: boolean) => {
      if (isSelected) {
        removeDestinationId(id);
      } else {
        addDestinationId(id);
      }

      setSearchTerm('');
    };

    useEffect(() => {
      searchInputRef.current?.focus();
    }, []);

    useEffect(() => {
      if (searchTerm === prevSearchTerm) {
        return;
      }
      destinationInputTracking({
        searchTerm,
        options: lookupResults || [],
        selectedOptions: selectedDestinationOptions,
        isOptionsLoaded: !isLoading,
        isListOpened: true,
      });
      setPrevSearchTerm(searchTerm);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
      if (isLoading) {
        return;
      }
      destinationLoadedTracking({
        searchTerm,
        options: lookupResults || [],
        selectedOptions: selectedDestinationOptions,
        isOptionsLoaded: !isLoading,
        isListOpened: false,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    useEffect(() => {
      destinationOptionTracking({
        searchTerm,
        options: lookupResults || [],
        selectedOptions: selectedDestinationOptions,
        isOptionsLoaded: !isLoading,
        isListOpened: true,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destinationIds]);

    useEffect(() => {
      if (lookupResults.length > 0) {
        setPrevLookupResults(lookupResults);
      }
    }, [lookupResults, setPrevLookupResults]);

    useUpdateEffect(() => {
      if (destinationIds.length === 1 && isAnyDestination(destinationIds[0])) {
        closeModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destinationIds]);

    return (
      <section data-id="destination-selector">
        <div sx={tagsAndInputWrapperStyles}>
          <div sx={innerInputWrapperStyles}>
            <SelectedTags
              tags={selectedDestinationOptions}
              onTagClick={(removedId) =>
                setDestinationIds(
                  destinationIds.filter((destinationId) => destinationId !== removedId),
                )
              }
            />
            <TextField
              ref={searchInputRef}
              data-id="destination-selector-input-field"
              sx={{
                flex: 1,
                minWidth: '120px', // Hardcoding minWidth so that the Add another placeholder text is always visible. Need to come up with a better solution if going international again.
              }}
              onChange={setSearchTerm}
              value={searchTerm}
              placeholder={
                selectedDestinationOptions.length > 0
                  ? t('searchUi.textPlaceholderFilled')
                  : t('searchUi.destinationInput.textPlaceholderEmpty')
              }
              onKeyDown={(e) => {
                if (e.key !== Key.Backspace || e.currentTarget.value.length) {
                  return;
                }

                const lastSelected =
                  selectedDestinationOptions[selectedDestinationOptions.length - 1];

                if (lastSelected) {
                  removeDestinationId(lastSelected.value);
                }
              }}
            />
          </div>
        </div>
        <div sx={mainContentWrapperStyles}>
          {isLoading && <LoadingResults />}

          {!isLoading &&
            (lookupResults.length === 0 ? (
              <Label
                sx={{
                  display: 'block',
                  marginTop: 'xl',
                }}
                variant="medium"
              >
                {t('noMatchesFound')}
              </Label>
            ) : (
              <div data-id="destination-results">
                <DestinationsSection
                  destinations={destinations}
                  onClick={onSelect}
                />

                <AccomodationsSection
                  accomodations={accomodations}
                  onHotelClick={handleHotelClick}
                />
              </div>
            ))}
        </div>
      </section>
    );
  },
);
